<template>
    <div class="merchandise--wrapper">
        <div class="merchandise--banner" v-if="!isTop">
            <h2 class="default--inner default--title"
                v-text="group.name"
                v-if="group.name" v-cloak>
            </h2>
            <img
                :src="'https:\/\/toyo-systemflyer.s3.ap-northeast-1.amazonaws.com\/public\/image\/'+group.banner"
                :alt="group.name"
                v-if="group.banner"
            >
        </div>
        <ul class="merchandise--list" :class="{ 'default--inner': !isTop }" :style="{background: group.color}">
            <MerchandiseItem v-for=" (item, index) in group.items " :key="index" :item="item"></MerchandiseItem>
        </ul>
    </div>
</template>

<script>
import MerchandiseItem from '@/components/MerchandiseItem.vue'

export default {
    name: 'MerchandiseList',
    data() {
        return {

        }
    },
    props: {
         isTop : {default: false },
         group: {default: {banner: null, name: null, themeColor: '#fff'}}
    },
    components: {
        MerchandiseItem
    }
}
</script>

<style scoped lang="sass">
    @import '@/assets/fonts/dohyeon/dohyeon-regular.sass'
    .merchandise
        &--banner
            img
                vertical-align: bottom
                width: 100%
        &--list
            display: flex
            flex-wrap: wrap
            &.default--inner
                margin-bottom: 45px
                padding-top: 8px

</style>