<template>
    <div id="chirashi">
      <div class="default--columns">
        <MerchandiseList v-for="(group, index) in groups" :key="index" :group="group" :id="'chirashi' + index"></MerchandiseList>
        <Campaign class="default--inner"></Campaign>
      </div>
    </div>
</template>

<script>
import MerchandiseList from '@/components/MerchandiseList.vue';
import Campaign from '@/components/Campaign.vue';

import MerchandiseJson from '@/assets/json/merchandise.json'
import GroupJson from '@/assets/json/group.json'
import FifteenJson from '@/assets/json/fifteen.json'
import SixteenJson from '@/assets/json/sixteen.json'
import SeventeenJson from '@/assets/json/seventeen.json'

let json = GroupJson

const today = new Date().getDate();

switch (today) {
  case 15:{
    json = FifteenJson
    break
  }
  case 16:{
    json = SixteenJson
    break
  }
  case 17:{
    json = SeventeenJson
    break
  }
}


export default {
  name: 'ChirashiTop',
  data(){
    return {
        groups: json,
        merchandises: MerchandiseJson,
        merchandiseJson: null,
    }
  },
  components: {
    MerchandiseList,
    Campaign
  },
  mounted: function(){
    let p = this

    this.merchandises.forEach((item) => {
        p.groups.forEach((g) => {
            if( item.id == g.id ) g.items.push(item)
        })
    })
  }
}
</script>

<style lang="sass" scoped>
</style>
