<template>
  <div id="shopInformation">
    <div class="default--columns">
      <ShopInfoTable></ShopInfoTable>
      <Campaign></Campaign>
    </div>
  </div>
</template>

  <script>
  import ShopInfoTable from '@/components/ShopInfoTable.vue'
  import Campaign from '@/components/Campaign.vue'


  export default {
    name: 'ShopInformation',
    components: {
      ShopInfoTable,
      Campaign
    }
  }
  </script>

  <style lang="sass" scoped>
    .shopinfo
      @include mq()
        padding-top: 45px
  </style>
