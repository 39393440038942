<template>
  <div>
    <div class="container">
      <img src="@/assets/img/logo.png" alt="スーパーTOYO" class="logo">
      <table width="100%">
        <tr>
          <td>
            <a href="/" class="shop-list--item--text">三島店</a>
          </td>
          <td>
            <a class="shop-list--item--text">沼津店</a>
          </td>
          <td>
            <a class="shop-list--item--text">静岡駅前店</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="shop-list--item--text">伊豆の国店</a>
          </td>
          <td>
            <a class="shop-list--item--text">掛川店</a>
          </td>
          <td>
            <a class="shop-list--item--text">三島駅北口店</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="shop-list--item--text">御殿場店</a>
          </td>
          <td>
            <a class="shop-list--item--text">西伊豆町店</a>
          </td>
          <td>
            <a class="shop-list--item--text">松崎店</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="shop-list--item--text">牧之原店</a>
          </td>
          <td>
            <a class="shop-list--item--text">藤枝駅前店</a>
          </td>
          <td>
            <a class="shop-list--item--text">下田北店</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="shop-list--item--text">南伊豆店</a>
          </td>
          <td>
            <a class="shop-list--item--text">森町店</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="container">
      <img src="@/assets/img/toyo-banner.png" alt="スーパーTOYO" class="toyo-banner">
      <table width="100%">
        <tr>
          <td>
            <a class="shop-list--item--text">静岡江川通り店</a>
          </td>
          <td>
            <a class="shop-list--item--text">裾野下和田店</a>
          </td>
          <td>
            <a class="shop-list--item--text">大谷店</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="shop-list--item--text">池田街道店</a>
          </td>
          <td>
            <a class="shop-list--item--text">長田店</a>
          </td>
          <td>
            <a class="shop-list--item--text">静岡手越原店</a>
          </td>
        </tr>
        <tr>
          <td>
            <a class="shop-list--item--text">東海道店</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="container">
      <img src="@/assets/img/marche-banner.png" alt="スーパーTOYO" class="marche-banner">
      <table width="100%">
        <tr>
          <td>
            <a class="shop-list--item--text">静岡江川通り店</a>
          </td>
          <td>
            <a class="shop-list--item--text">裾野下和田店</a>
          </td>
          <td>
            <a class="shop-list--item--text">大谷店</a>
          </td>
        </tr>
      </table>
    </div>
  </div>

</template>

<script>
export default {
  name: "ShopList",
}
</script>

<style lang="sass" scoped>
a
  color: $black
  font-size: 13px
  text-decoration: underline
a:visited
  color: $black
.container
  margin: 10px 10px
.logo
  width: 30%
.toyo-banner
  width: 15%
.marche-banner
  width: 20%

</style>