<template>
    <router-link v-if="item" class="global--tag" :to="`/searchResult?tag=${item.name}`" v-text="item.name"></router-link>
</template>

<script>
    export default {
        name: 'TagItem',
        data(){
            return {
            }
        },
        props: {
            item: { default: null },
            path: {default: null}
        },
        components: {},
        mounted: function(){

        }
    }
</script>

<style scoped lang="sass">
    .global--tag
        display: inline-block
        padding: 2px 5px
        border: 1px solid $line
        border-radius: 2px
        line-height: 1.5
        font-size: 1.3rem
        font-weight: 500
        color: $text-default
        &:not(:last-child)
            margin-right: 3px
</style>
