<template>
    <footer id="global--footer">
        <nav class="global--navi">
            <ul class="global--navi-list">
                <li class="global--navi-item">
                    <router-link
                        :to="{name:'ChirashiTop'}"
                        active-class="-active"
                    >
                        <font-awesome-icon class="global--navi-icon" icon="fa-solid fa-basket-shopping"></font-awesome-icon>
                        <span class="global--navi-text">チラシ</span>
                    </router-link>
                </li>
                <li class="global--navi-item">
                    <router-link
                        :to="{name:'TimeLine'}"
                        active-class="-active"
                    >
                        <font-awesome-icon class="global--navi-icon" icon="fa-solid fa-list"></font-awesome-icon>
                        <span class="global--navi-text">タイムライン</span>
                    </router-link>
                </li>
                <li class="global--navi-item">
                    <router-link
                        :to="{name: 'ShopInformation'}"
                        active-class="-active"
                    >
                        <font-awesome-icon class="global--navi-icon" icon="fa-solid fa-store"></font-awesome-icon>
                        <span class="global--navi-text">店舗情報</span>
                    </router-link>
                </li>
                <li class="global--navi-item">
                    <button type="button" @click="$emit('openSearchWindow')">
                        <font-awesome-icon class="global--navi-icon" icon="fa-solid fa-magnifying-glass"></font-awesome-icon>
                        <span class="global--navi-text">商品を探す</span>
                    </button>
                </li>
            </ul>
        </nav>
    </footer>
</template>

<script>
    export default {
        name: 'TagItem',
        data(){
            return {
            }
        },
        props: {
            item: { default: null }
        },
        components: {},
        mounted: function(){},
        methods: {}
    }
</script>

<style scoped lang="sass">
    #global--footer
        @include mq()
            display: none
    .global--navi
        position: fixed
        z-index: 99
        bottom: 0
        width: 100vw
        background-color: $white
        &-list
            display: flex
            border-radius: 5px 5px 0 0
            border-top: 1px solid $line
        &-item
            width: 25%
            text-align: center
            a, button
                display: block
                width: 100%
                padding: 12.5px 0 10.5px
                color: $gray-deep
                line-height: 1.6
                svg
                    color: $gray-default
            .-active
                svg
                    color: $primary
        &-icon
            font-size: 24px
        &-text
            display: block
            font-size: 9.5px
            font-weight: 500
</style>