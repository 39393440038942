<template>
  <div>
    <div class="tag">
      <p class="tag-title">タグ検索：</p>
    </div>
    <div class="tag">
      <p class="global--tag">鍋物</p>
    </div>
    <div class="right-item">
      <div class="tag">
        <div class="sort-item">
          <font-awesome-icon icon="fa-solid fa-arrow-down-wide-short" />
        </div>
        <div class="sort-item">
          <p class="right-item-text">標準</p>
        </div>
      </div>
    </div>
    <div id="chirashi">
      <div class="default--columns">
        <MerchandiseList v-for="(group, index) in groups" :key="index" :group="group"></MerchandiseList>
        <Campaign class="default--inner"></Campaign>
      </div>
    </div>
  </div>
</template>

<script>
import MerchandiseList from '@/components/MerchandiseList.vue';
import Campaign from '@/components/Campaign.vue';

// import MerchandiseCSV from '@/assets/csv/merchandise.csv'
import MerchandiseJson from '@/assets/json/merchandise.json'
import PotJson from '@/assets/json/pot.json'

export default {
  name: 'SearchResult',
  data(){
    return {
      groups: PotJson,
      merchandises: MerchandiseJson,
      merchandiseJson: null,
    }
  },
  components: {
    MerchandiseList,
    Campaign
  },
  mounted: function(){
    let p = this

    // this.$papa.parse(this.merchandises, {
    //     header: true,
    //     dynamicTyping: false,
    //     complete: function(results) {
    //       p.groups = results.data;
    //       console.log(p.groups);
    //     },
    // });

    this.merchandises.forEach((item) => {
      p.groups.forEach((g) => {
        if( item.id == g.id ) g.items.push(item)
      })
    })
    console.log(this.groups)
  }
}
</script>

<style scoped lang="sass">
.global--tag
  display: inline-block
  padding: 2px 5px
  border: 1px solid $line
  border-radius: 2px
  line-height: 1.5
  font-size: 1.3rem
  font-weight: 500
  color: $text-default
  &:not(:last-child)
    margin-right: 3px
.tag
  display: inline-block
.sort-item
  display: inline-block
.tag-title
  padding-left: 10px
  margin-bottom: 30px
.right-item
  float: right
  &-text
    margin-left: 5px
    margin-right: 10px
</style>
