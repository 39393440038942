<template>
    <div class="campaign default--wrapper default--inner">
        <h2 class="default--title">イベント・キャンペーン</h2>
        <ul class="campaign--list">
            <li class="campaign--item">
                <a href="" :style="'backgroundImage: url('+campaign1+')'"></a>
            </li>
            <li class="campaign--item">
                <a href="" :style="'backgroundImage: url('+campaign2+')'"></a>
            </li>
            <li class="campaign--item">
                <a href="" :style="'backgroundImage: url('+campaign3+')'"></a>
            </li>
            <li class="campaign--item">
                <a href="" :style="'backgroundImage: url('+campaign4+')'"></a>
            </li>
        </ul>
    </div>
</template>

<script>
import campaign1 from '@/assets/img/campaign/event-01.png'
import campaign2 from '@/assets/img/campaign/event-02.png'
import campaign3 from '@/assets/img/campaign/event-03.png'
import campaign4 from '@/assets/img/campaign/event-04.png'

export default {
    name: 'Campaign',
    data() {
        return {
            campaign1: campaign1,
            campaign2: campaign2,
            campaign3: campaign3,
            campaign4: campaign4
        }
    },
    props: {
         isTop : {default: false }
    }
}
</script>

<style scoped lang="sass">
    .campaign
        @include mq()
            position: absolute
            top: 0
            right: 10px
            width: 220px
            margin-top: 0
    .campaign--list
        display: flex
        flex-wrap: wrap
        @include mq()
    .campaign--item
        overflow: hidden
        width: 49%
        height: 218px
        border-radius: 3px
        margin-bottom: 8px
        box-shadow: 0px 3px 6px $shadow
        @include mq()
            width: 100%
            margin-right: 0
        &:not(:nth-child(2n))
            margin-right: 2%
            @include mq()
                margin-right: 0
        a
            display: block
            width: 100%
            height: 100%
            background-position: center
            background-size: cover
</style>