
import Home from "@/views/Home";
import ChirashiTop from "@/views/ChirashiTop";
import ChirashiItem from "@/views/ChirashiItem";
import ShopInformation from "@/views/ShopInformation";
import TimeLine from "@/views/TimeLine";
import Vue from "vue";
import Router from "vue-router";
import ShopTop from "@/views/ShopTop";
import SearchResult from "@/views/SearchResult";
import PotSearchResult from "@/views/PotSearchResult";
import CategoryResult from "@/views/CategoryResult";
import TodayResult from "@/views/TodayResult";
import NextDayResult from "@/views/NextDayResult";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: "",
      name: "Home",
      component: Home,
    },
    {
      path: "/chirashi",
      name: "ChirashiTop",
      component: ChirashiTop,
    },
    {
      path: "/item/:item_id",
      name: "ChirashiItem",
      component: ChirashiItem,
      props: true,
    },
    {
      path: "/shop",
      name: "ShopInformation",
      component: ShopInformation,
    },
    {
      path: "/timeline",
      name: "TimeLine",
      component: TimeLine,
    },
    {
      path: "/shopTop",
      name: "shopTop",
      component: ShopTop,
    },
    {
      path: "/searchResult",
      name: "SearchResult",
      component: SearchResult,
    },
    {
      path: "/potSearchResult",
      name: "potSearchResult",
      component: PotSearchResult,
    },
    {
      path: "/categoryResult",
      name: "categoryResult",
      component: CategoryResult,
    },
    {
      path: "/toDayResult",
      name: "toDayResult",
      component: TodayResult,
    },
    {
      path: "/nextDayResult",
      name: "nextDayResult",
      component: NextDayResult,
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});
