<template>
    <li class="timeline--item"> 
        <div class="timeline--item-header">
            <span class="timeline--item-label" :class="'-'+info.label"></span>
        </div>
        <div class="timeline--item-body" v-html="info.content"></div>
        <div class="timeline--item-footer">
            <span class="timeline--item-timing" v-text="info.timing"></span>
        </div>
    </li>
</template>

<script>  
export default {
  name: 'InformationItem',
  props: { info: {default: null} }
}
</script>

<style lang="sass" scoped>
    .timeline--item
        padding-bottom: 18px
        margin-bottom: 16px
        &:not(:last-child)
            border-bottom: 1px solid $border
        &-label
            display: inline-block
            min-width: 70px
            border: 1px solid 
            border-radius: 2px
            text-align: center
            font-size: 1.1rem
            font-weight: 500
            line-height: 1.6
            &.-kokuchi
                color: #BF7600
                background: #F7EFE2
                &:before
                    content: '告知'
            &.-timesale
                color: #008E0B
                background: #E9F7EA
                &:before
                    content: 'タイムセール'
        &-body
            font-size: 1.4rem
            line-height: 1.75
            white-space: break-spaces
            img
                margin: 6px 0
        &-timing
            display: block
            text-align: right
            font-size: 11px
            color: $text-gray
                    


</style>