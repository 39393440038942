<template>
    <li class="merchandise--item" :class="{ '-isRealte' : isRelate }">
        <router-link :to="{name: 'ChirashiItem', params: {item_id: 1} }">
          <ul class="merchandise--tags" v-if="item.icon">
            <li class="merchandise--tags-icon" v-for=" (icon, index) in item.icon.split(',')" :key="index" v-text="icon" style="" :id="'category' + index"></li>
          </ul>
            <div v-if="!item.image || item.image === ''" class="merchandise--thumb -empty"><font-awesome-icon icon="fa-solid fa-square-xmark" /><span class></span></div>
            <div v-else class="merchandise--thumb" :style="{backgroundImage: 'url(https:\/\/toyo-systemflyer.s3.ap-northeast-1.amazonaws.com\/public\/image\/'+item.image+'.png)'}"></div>
            <div class="merchandise--body">
                <div class="merchandise--body-detail">
                    <span class="merchandise--body-name" v-text="item.name"></span>
                    <span class="merchandise--body-standard" v-text="item.standard1"></span>
                    <span class="merchandise--body-standard" v-text="item.standard2"></span>
                </div>
                <div class="merchandise--body-price">
                    <span class="yen">¥</span>
                    <span class="price" v-text="item.price"></span>
                    <span class="tax">税込</span>
                </div>
            </div>
            <ul class="merchandise--tags" v-if="item.tags">
                <li class="merchandise--tags-item" v-for=" (tag, index) in item.tags.split(',')" :key="index" v-text="tag"></li>
            </ul>
        </router-link>
    </li>
</template>


<script>
export default {
    name: 'MerchandiseList',
    data() {
        return {

        }
    },
    props: {
        item: { default: null },
        isRelate: {default: false}
    }
}
</script>

<style scoped lang="sass">
    @import '@/assets/fonts/dohyeon/dohyeon-regular.sass'
    #category1
      background-color: #EB9100
    .merchandise
        &--banner
            img
                vertical-align: bottom
        &--item
            width: 32%
            margin-bottom: 11px
            padding: 8px
            border-radius: 3px
            box-shadow: 0px 3px 6px $shadow
            background: $white
            &:not(:nth-child(3n + 2))
                margin-right: 2%
            &:nth-child(-n + 2)
                width: 49%
                margin-bottom: 11px
                margin-right: 0
                padding: 8px
                border-radius: 3px
                box-shadow: 0px 3px 6px $shadow
                font-size: 1.3rem
                .merchandise--thumb
                    height: 105px
                .price
                    font-size: 3.5rem
            &:first-child
                margin-right: 2%
            &.-isRealte
                margin-right: 0
                .merchandise--thumb
                    height: 75px
                &:not(:nth-child(3))
                    margin-right: 2%
                &:nth-child(-n + 2)
                    width: 32%
                    padding: 8px
            a
                color: $text-default
        &--thumb
            width: 100%
            height: 75px
            margin-bottom: 8px
            border-radius: 3px
            background-size: contain
            background-position: center
            text-align: center
            &.-empty
                display: flex
                justify-content: center
                align-items: center
                background: $gray-light-2
                color: $gray-deep
                svg
                    display: block
                    margin: 0 auto 4px
                    font-size: 4rem
                    opacity: .4
        &--body
            font-size: 1.2rem
            line-height: 1.5
            &-detail
                overflow: hidden
                height: 40px
            &-name
                font-weight: 500
                padding-right: 4px
            &-standard
                margin-left: 0.3rem
            &-price
                line-height: 1.3
                color: $text-price
                .yen
                    font-size: 1.5rem
                    vertical-align: text-bottom
                .price
                    font-size: 3rem
                    font-family: $font-price
                .tax
                    padding-left: 3px
                    vertical-align: text-bottom
                    font-size: 1.1rem
                    color: $text-default
        &--tags
            display: flex
            flex-wrap: wrap
            &-item
                display: inline-block
                margin-bottom: 2px
                padding: 0 3px
                border: 1px solid $line
                border-radius: 2px
                line-height: 1.5
                font-size: 1.2rem
                &:not(:last-child)
                    margin-right: 3px
            &-icon
              display: inline-block
              color: white
              margin-bottom: 2px
              padding: 0 3px
              border: 1px solid $line
              border-radius: 2px
              line-height: 1.5
              font-size: 1.2rem
              background-color: #DD0707
              &:not(:last-child)
                margin-right: 3px
</style>