<template>
  <div>
    <div id="chirashi--top">
      <div class="chirashi--slider">
        <ChirashiSlider></ChirashiSlider>
      </div>
      <div class="default--columns">
        <div class="top--timeline-wrapper default--wrapper default--inner">
          <h2 class="default--title">
            スーパーTOYO三島店からのお知らせ
            <router-link to="/" class="default--more">もっと見る<font-awesome-icon class="header--top-arrow" icon="fa-solid fa-angle-right" /></router-link>
          </h2>
          <ul class="timeline--list">
            <li class="timeline--item">
              <div class="timeline--body">
                <span class="timeline--body-tag -timesale"></span>
                本日12月14日15:00から、お刺身売り場で30分間のタイムセールを開催します！「夜の献立にもう一品」「お酒のあてに」鮮度抜群、厚みのあるお刺身が揃っておりますので、是非お買い求めください。
              </div>
              <div class="timeline--footer">
                <span class="timeline--footer-timing">30分前</span>
              </div>
            </li>
            <li class="timeline--item">
              <div class="timeline--body">
                <span class="timeline--body-tag -kokuchi"></span>
                毎週水曜日は、ポイント5倍デー開催！！ポイント5倍対象となるお支払い方法は、現金とクレジットカードののみとなります。
              </div>
              <div class="timeline--footer">
                <span class="timeline--footer-timing">30分前</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="top--chirashi-wrapper default--wrapper default--inner">
          <h2 class="default--title">チラシに掲載されている商品</h2>
          <MerchandiseList :isTop="true" :group="today"></MerchandiseList>
          <router-link to="/chirashi" class="button button--primary">
            <font-awesome-icon icon="fa-regular fa-hand-point-right" />
            チラシをチェックする
          </router-link>
        </div>

        <div class="top--shopinfo-wrapper default--wrapper">
          <h2 class="default--title default--inner">店舗情報</h2>
          <ShopInfoTable></ShopInfoTable>
        </div>

        <Campaign :isTop="true"></Campaign>
      </div>
    </div>
  </div>
</template>

<script>
import ChirashiSlider from '@/components/ChirashiSlider.vue'
import MerchandiseList from '@/components/MerchandiseList.vue'
import ShopInfoTable from '@/components/ShopInfoTable.vue'
import Campaign from '@/components/Campaign.vue'

import TodayMerchandise from '@/assets/json/todayMerchandise.json'


export default {
  name: 'Home',
  data(){
    return {
      today: { "id": 6, "banner": null, "name": "null", "color": "#fff", "items": [] },
      items: TodayMerchandise
    }
  },
  components: {
    ChirashiSlider,
    MerchandiseList,
    ShopInfoTable,
    Campaign
  },
  created: function() {
    this.today.items = this.items
  }
}
</script>

<style lang="sass" scoped>
  .chirashi--slider
    padding-bottom: 4px
    background: $gray-light-3
  .timeline
    &--wrapper
      padding: 35px 0 0
    &--item
      padding-bottom: 8px
      border-bottom: 1px solid $line
      &:not(:first-child)
        padding-top: 6px
      &:last-child
        border-bottom: 0
    &--body
      position: relative
      overflow: hidden
      height: 50px
      font-size: 13px
      &:before
        position: absolute
        z-index:2
        bottom: 0
        right: 0
        content: '…'
        display: inline-block
        padding: 0 5.2px
        background: $white

      &-tag
        display: inline-block
        min-width: 72px
        margin-right: 3px
        padding: 0 4px
        border: 1px solid
        border-radius: 2px
        font-size: 11px
        text-align: center
        line-height: 1.7
        &.-kokuchi
            color: #BF7600
            background: #F7EFE2
            &:before
                content: '告知'
        &.-timesale
            color: #008E0B
            background: #E9F7EA
            &:before
                content: 'タイムセール'
    &--footer
        line-height: 1.6
        &-timing
          display: block
          text-align: right
          font-size: 11px
          color: $text-gray

</style>
