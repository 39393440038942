<template>
    <slick
        ref="slick"
        :options="slickOptions"
        @afterChange="handleAfterChange"
        @beforeChange="handleBeforeChange"
        @breakpoint="handleBreakpoint"
        @destroy="handleDestroy"
        @edge="handleEdge"
        @init="handleInit"
        @reInit="handleReInit"
        @setPosition="handleSetPosition"
        @swipe="handleSwipe"
        @lazyLoaded="handleLazyLoaded"
        @lazyLoadError="handleLazyLoadError">
        <a href="/chirashi"><img src="@/assets/img/merchandise/banner-01.png" alt=""></a>
        <a href="/chirashi#chirashi1"><img src="@/assets/img/merchandise/banner-02.png" alt=""></a>
        <a href="/chirashi#chirashi2"><img src="@/assets/img/merchandise/banner-03.png" alt=""></a>
    </slick>
</template>
<script>
    /* eslint-disable */
    import Slick from 'vue-slick';

    export default {
        components: { Slick },
        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "20%",
                    infinite: true,
                    accessibility: true,
                    adaptiveHeight: false,
                    arrows: false,
                    dots: true,
                    draggable: true,
                    edgeFriction: 0.30,
                    swipe: true,
                    autoplay: true,
                    speed: 400,
                    responsive: [{
                        breakpoint: 560,
                        settings: {
                            centerMode: false,
                            slidesToShow: 1
                        }
                    }]
                },
            };
        },
        methods: {
            next() {
                this.$refs.slick.next();
            },
            prev() {
                this.$refs.slick.prev();
            },
            reInit() {
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },
            handleAfterChange(event, slick, currentSlide) {
                console.log('handleAfterChange', event, slick, currentSlide);
            },
            handleBeforeChange(event, slick, currentSlide, nextSlide) {
                console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
            },
            handleBreakpoint(event, slick, breakpoint) {
                console.log('handleBreakpoint', event, slick, breakpoint);
            },
            handleDestroy(event, slick) {
                console.log('handleDestroy', event, slick);
            },
            handleEdge(event, slick, direction) {
                console.log('handleEdge', event, slick, direction);
            },
            handleInit(event, slick) {
                console.log('handleInit', event, slick);
            },
            handleReInit(event, slick) {
                console.log('handleReInit', event, slick);
            },
            handleSetPosition(event, slick) {
                console.log('handleSetPosition', event, slick);
            },
            handleSwipe(event, slick, direction) {
                console.log('handleSwipe', event, slick, direction);
            },
            handleLazyLoaded(event, slick, image, imageSource) {
                console.log('handleLazyLoaded', event, slick, image, imageSource);
            },
            handleLazyLoadError(event, slick, image, imageSource) {
                console.log('handleLazeLoadError', event, slick, image, imageSource);
            },
        },
    }
</script>

<style lang="sass">
    @import 'slick-carousel/slick/slick.css'
    @import 'slick-carousel/slick/slick-theme.css'
    .slick-dots
        bottom: -20px
        li
            margin: 0
    .slick-dots li.slick-active button:before
        opacity: 1
        color: $primary

</style>