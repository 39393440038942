import Vue from 'vue'
import App from './App.vue'
import AdobeFont from './plugins/adobe-font';
import VuePapaParse from "vue-papa-parse";
import router from './router'
import { createHead, HeadVuePlugin } from "@vueuse/head"

import 'ress'
import '@/assets/sass/style.sass'

/* fontawsome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faAngleRight, faBasketShopping, faList, faStore, faMagnifyingGlass,
  faPhone, faLocationDot, faHeart, faSquareXmark, faArrowDownWideShort,faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons'
import {
  faHandPointRight,faMap
} from '@fortawesome/free-regular-svg-icons'

library.add(faAngleRight)
library.add(faBasketShopping)
library.add(faList)
library.add(faStore)
library.add(faMagnifyingGlass)
library.add(faHandPointRight)
library.add(faPhone)
library.add(faMap)
library.add(faLocationDot)
library.add(faHeart)
library.add(faSquareXmark)
library.add(faArrowDownWideShort)
library.add(faArrowUpRightFromSquare)
Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.component('v-slick', require('@/components/ChirashiSlider.vue'));


/* pulguins */
Vue.use(AdobeFont);
const head = createHead()
Vue.use(HeadVuePlugin, head)
Vue.use(head)
Vue.use(VuePapaParse)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
