<template>
  <div>
    <li class="campaign--item">
      <img class="background" href="" :style="'backgroundImage: url('+background+')'">
    </li>
    <P class="top-text">毎日がお買い得！</P>
    <img src="@/assets/img/logo.png" alt="スーパーTOYO" class="big-logo">
    <img src="@/assets/img/toyo-banner.png" alt="TOYOストア" class="small-logo1">
    <img src="@/assets/img/marche-banner.png" alt="マルシェTOYO" class="small-logo2">
    <div class="card-view">
      <p class="card-title">最近見た店舗</p>
      <ul class="shop-list">
        <li class="shop-list--item">
          <a class="shop-list--item--text">スーパーTOYO 三島店</a>
        </li>
        <li class="shop-list--item">
          <a class="shop-list--item--text">スーパーTOYO 三島駅北口店</a>
        </li>
        <li class="shop-list--item">
          <a class="shop-list--item--text">TOYO STORE 掛川店</a>
        </li>
      </ul>
    </div>
    <div class="search-area">
    <p class="search-area--title">場所から探す</p>
      <div class="search-area--item">
        <router-link to="/shopHomePage" class="button button--primary button-item">
          <font-awesome-icon class="shopinfo--table-iconButton" icon="fa-solid fa-location-dot" />
          現在地から検索
        </router-link>
        <div class="search-area--space"></div>
        <router-link to="/shopHomePage" class="button button--sub">
          <font-awesome-icon icon="fa-regular fa-map" />
          エリアから検索
        </router-link>
      </div>
    </div>
    <div class="search-business-type">
      <p class="search-business-type--title">業態・店舗ごとに探す</p>
      <ShopList></ShopList>
    </div>
    <Campaign :isTop="true"></Campaign>
    <footer class="shop-top-footer">
      <router-link :to={} class="shop-top-footer-link" active-class="-active">ご利用について</router-link>
      <span class="shop-top-footer-delimiter">|</span>
      <router-link :to={} class="shop-top-footer-link" active-class="-active">お問合せ</router-link>
      <span class="shop-top-footer-delimiter">|</span>
      <router-link :to={} class="shop-top-footer-link" active-class="-active">運営会社</router-link>
    </footer>
  </div>
</template>

<script>
import toyoBanner from '@/assets/img/toyo-banner.png'
import marche from '@/assets/img/marche-banner.png'
import background from '@/assets/img/background.png'
import ShopList from "@/components/ShopList";
import Campaign from "@/components/Campaign";

export default {
  name: 'ShopTop',
  components: {
    ShopList,
    Campaign
  },
  data() {
    return {
      toyoBanner: toyoBanner,
      marche: marche,
      background: background,
    }
  },
  props: {
    isTop : {default: false }
  }
}
</script>

<style scoped lang="sass">
header
  height: 45px
  width: 100%
  padding-top: 12px
p
  margin-top: 18px
  text-align: center
  font-size: 12px
.header-logo
  width: 30%
  height: auto
  display: block
  margin: auto
.top-text
  font-size: 18px
  position: absolute
  top: 65px
  left: 22px
  font-weight: bold
.campaign--item
  overflow: hidden
  width: 100%
  height: 345px
  .background
    display: block
    width: 100%
    height: 100%
    background-position: center
    background-size: cover
    object-fit: cover
.big-logo
  position: absolute
  top: 153px
  width: 80%
  height: auto
  left: 0
  right: 0
  margin: auto
.small-logo1
  position: absolute
  top: 231px
  left: 16%
  width: 30%
  height: auto
.small-logo2
  position: absolute
  top: 231px
  left: 53%
  width: 32%
  height: auto
.card-view
  position: absolute
  background-color: white
  height: 130px
  width: 90%
  top: 330px
  left: 0
  right: 0
  margin: auto
  border-radius: 8px
  box-shadow: 0px 3px 6px $shadow
.shop-list
  margin-left: 30px
  padding-top: 0
  padding-bottom: 0
  &--item
   height: 12px
   margin-bottom: 12px
   &--text
    color: $black
    font-size: 12px
    text-decoration: underline
.search-area
  margin: 0 10px
  &--title
   margin-top: 120px
   margin-bottom: 4px
  &--item
   display: flex
   justify-content: space-between
  &--space
   width: 20px
.shop-top-footer
  text-align: center
  margin-top: 10px
  padding-bottom: 10px
  .shop-top-footer-delimiter
    color: #6E6E6E
    font-size: 10px
  .shop-top-footer-link
    color: #6E6E6E
    font-size: 10px
    padding: 0 2px
</style>

<style  lang="sass">
  //ヘッダーとフッダーを無効化
  //#global--header
  //  display: none
  //#global--footer
  //  display: none
  //#app
  //  padding-bottom: 0 !important
</style>
