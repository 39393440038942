<template>
    <div id="chirashiItem">
        <div class="chirashiItem--header default--inner">
            <div class="chirashiItem--header-tag">
                <span class="chirashiItem--header-heading">関連するタグ</span>
                <TagItem v-for=" (tag, index) in tags " :item="tag" :key="index"></TagItem>
            </div>
            <div class="chirashiItem--header-category">
                <span class="chirashiItem--header-heading">関連するカテゴリー</span>
                <router-link to="/" class="category">お肉</router-link>
            </div>
        </div>
        <div class="chirashiItem--body default--inner">
            <div class="chirashiItem--body-status">
                この商品は
                <span class="timing dot -today">本日</span>
                <span class="timing -today">のお買い得</span>
                です
            </div>
          <div class="icon-container">
            <ul class="icon-list">
              <li class="icon-list-item">
                イチオシ
              </li>
              <li class="icon-list-item" style="background-color: #EB9100">
                レシピ付き
              </li>
            </ul>
          </div>
            <div class="chirashiItem--body-image" :style="{backgroundImage: 'url(https:\/\/dev.cortechne.jp\/toyoprint\/systemflyer\/public/\/merchandise\/'+item.image+'.png)'}">
                <ul class="chirashiItem--body-labels">
                    <li class="chirashiItem--body-label -ichioshi"></li>
                    <li class="chirashiItem--body-label -recipe"></li>
                </ul>
                <button class="chirashiItem--body-favorite" @click="toggleFavorite" :class="{'-active': favoriteStatus}"><font-awesome-icon icon="fa-solid fa-heart" /></button>
            </div>
            <div class="chirashiItem--body-detail">
                <h2 class="chirashiItem--body-heading">
                    <span class="standard1">アンガス産</span>
                    <span class="name">アンガス牛肩ロースすき焼き用</span>
                    <span class="standard2">&lt;解凍&gt;800g</span>
                </h2>
                <div class="chirashiItem--body-price">
                    <span class="yen">¥</span>
                    <span class="price">1,814</span>
                    <span class="tax">(税込)</span>
                    <span class="standard3">100g当たり 210円</span>
                </div>
                <p class="chirashiItem--body-comment">
                    【食べ応え抜群！】<br>
                    すき焼き用に7mmにカットされているため、様々なお料理にお使いいただけます。<br>
                    上質な赤身肉となっておりサシも多めに入っているのが特徴です。
                </p>
                <p class="chirashiItem--body-annotation">
                    ※仕入れ状況によって産地が変更になる可能性がございます。<br>
                    ※写真はイメージです。実物とは異なる場合がございます。
                </p>
            </div>
        </div>

        <div class="chirashiItem--recipe default--inner default--wrapper">
            <h2 class="default--title">
                この商品に関連するレシピ
                <router-link to="/" class="default--more">もっと見る<font-awesome-icon class="header--top-arrow" icon="fa-solid fa-angle-right" /></router-link>
            </h2>
            <ul class="chirashiItem--recipe-list">
                <li class="chirashiItem--recipe-item" :style="{backgroundImage: 'url(https:\/\/dev.cortechne.jp\/toyoprint\/systemflyer\/public\/recipe\/mz150501009rcpA.jpg)'}"><a href="https://recipe.toyo-dev.com/recipe1" style="color: #ffffff;"><span class="name">牛肉とトマト&アスパラの香味炒め</span></a></li>
                <li class="chirashiItem--recipe-item" :style="{backgroundImage: 'url(https:\/\/dev.cortechne.jp\/toyoprint\/systemflyer\/public\/recipe\/mz150508004rcpA.jpg)'}"><a href="https://recipe.toyo-dev.com/recipe2" style="color: #ffffff;"><span class="name">牛肉のスタミナ丼</span></a></li>
                <li class="chirashiItem--recipe-item" :style="{backgroundImage: 'url(https:\/\/dev.cortechne.jp\/toyoprint\/systemflyer\/public\/recipe\/75954816.jpg)'}"><span class="name">牛肉のペッパーランチ</span></li>
            </ul>
        </div>

        <div class="chirashiItem--relation default--inner default--wrapper">
            <h2 class="default--title">
                この商品に関連する商品
                <router-link to="/" class="default--more">もっと見る<font-awesome-icon class="header--top-arrow" icon="fa-solid fa-angle-right" /></router-link>
            </h2>
            <ul class="chirashiItem--relation-list">
                <MerchandiseItem v-for=" (relate, index) in relations " :key="index" :item="relate" :isRelate="true"></MerchandiseItem>
            </ul>
        </div>

        <div class="chirashiItem--chirashi default--wrapper">
            <h2 class="default--title default--inner">
                本日のチラシ
            </h2>
            <ChirashiSlider></ChirashiSlider>
        </div>

        <Campaign></Campaign>
    </div>
</template>

<script>
    import MerchandiseItem from '@/components/MerchandiseItem.vue';
    import Campaign from '@/components/Campaign.vue';
    import ChirashiSlider from '@/components/ChirashiSlider.vue';
    import TagItem from '@/components/TagItem.vue';

    import RelationJson from '@/assets/json/relation.json';

    export default {
        name: 'ChirashiTop',
        data(){
            return {
                item: {
                    image: '061_ty140308021',
                },
                relations:  RelationJson,
                tags: [
                    { name: '本日のお買い得', path: '/'},
                    { name: 'お鍋', path: '/'}
                ],

                favoriteStatus: false
            }
        },
        props: [ 'item_id' ],
        components: {
            MerchandiseItem,
            Campaign,
            ChirashiSlider,
            TagItem
        },
        mounted: function(){},
        methods: {
            toggleFavorite: function(){
                this.favoriteStatus = !this.favoriteStatus
            }
        }
    }
</script>

<style lang="sass" scoped>
    .icon-container
      z-index: 20
      position: absolute
    .icon-list
      display: flex
    .icon-list-item
      margin-left: 5px
      display: inline-block
      margin-bottom: 2px
      padding: 0 3px
      border: 1px solid $line
      border-radius: 4px
      line-height: 1.5
      font-size: 1.2rem
      background-color: #DD0707
      color: white
    .chirashiItem
        &--header
            margin-bottom: 20px
            &-heading
                margin-right: 12px
                font-size: 1.4rem
                font-weight: 600

            .category
                text-decoration: underline
                font-weight: 500
        &--body
            &-status
                text-align: center
                font-weight: 500
                font-size: 1.5rem
                .timing
                    font-size: 1.9rem
                    font-weight: 600
                .dot
                    padding-top: .5em
                    background-position: top left 0px
                    background-repeat: repeat-x
                    background-size: 1em .3em
                    background-image: radial-gradient(.1em .1em at center center,$primary,$primary 100%,transparent)
            &-image
                position: relative
                width: 100%
                height: 240px
                margin-bottom: 38px
                background-position: center
                background-size: cover
            &-favorite
                position: absolute
                bottom: -20px
                right: 0px
                width: 40px
                height: 40px
                border-radius: 50px
                box-shadow: 0px 3px 6px $shadow
                font-size: 2.2rem
                color: $gray-opacity
                &.-active
                    color: $favorite
            &-heading
                padding-bottom: 10px
                font-size: 1.9rem
                font-weight: 600
                line-height: 1.5
                .standard1
                    margin-right: 5px
                .standard2
                    display: inline-block
                    margin-left: 5px
                    font-weight: 500
            &-price
                line-height: 1.3
                color: $text-price
                font-weight: 500
                .yen
                    font-size: 2.2rem
                    vertical-align: text-bottom
                .price
                    margin: 0 4px
                    font-size: 4.6rem
                    font-family: $font-price
                .tax, .standard3
                    padding-left: 3px
                    vertical-align: text-bottom
                    font-size: 1.6rem
                    color: $text-default
            &-comment
                font-size: 1.4rem
                line-height: 1.5
            &-annotation
                margin-top: 10px
                font-size: 1.2rem
                line-height: 1.5
                color: $gray-deep
        &--recipe
            &-list
                display: flex
            &-item
                position: relative
                overflow: hidden
                width: 32%
                height: 90px
                border-radius: 8px
                background-position: center
                background-size: cover
                font-size: 1.2rem
                font-weight: 500
                color: $white
                &:not(:last-child)
                   margin-right: 2%
                &:before
                    position: abusolute
                    z-index: 2
                    top: 0
                    left: 0
                    content: ''
                    display: block
                    width: 100%
                    height: 100%
                    background: rgba( #000, .65 )
                .name
                    position: absolute
                    z-index: 3
                    top: 5%
                    right: 5%
                    width: 90%
                    text-align: right
        &--relation-list
            display: flex
            flex-wrap: wrap



</style>