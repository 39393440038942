<template>
  <div>
  <div class="tag">
    <p class="tag-title">タグ検索：</p>
  </div>
  <div class="tag">
    <p class="global--tag">{{tagName}}</p>
  </div>
    <div class="right-item">
      <div class="tag">
        <div class="sort-item">
          <font-awesome-icon icon="fa-solid fa-arrow-down-wide-short" />
        </div>
        <div class="sort-item">
          <p class="right-item-text">標準</p>
        </div>
      </div>
    </div>
    <div id="chirashi">
      <div class="default--columns">
        <MerchandiseList v-for="(group, index) in groups" :key="index" :group="group" :is-top="isTop"></MerchandiseList>
        <Campaign class="default--inner"></Campaign>
      </div>
  </div>
  </div>
</template>

<script>
import MerchandiseList from '@/components/MerchandiseList.vue';
import Campaign from '@/components/Campaign.vue';

// import MerchandiseCSV from '@/assets/csv/merchandise.csv'
import MerchandiseJson from '@/assets/json/merchandise.json'
import FareJson from '@/assets/json/fare.json'

export default {
  name: 'SearchResult',
  data(){
    return {
      tagName: '',
      isTop: false,
      // タグによっての内容出し分け用
      tagList: {
        mushroomName: ['ぶなしめじ', '舞茸', 'えのき茸'],
        bargainPricedName: ['ぶなしめじ', 'ミニトマト', '国産若鶏もも肉 5枚入パック', '国産若鶏もも肉', '上白糖'],
        stewName: ['玉ねぎ', 'ぶなしめじ', '国産若鶏もも肉', '北海道産四元豚ロース切り身 4枚入パック', ],
        saladName: ['ミニトマト', '玉ねぎ', 'シナノスィート\nりんご', 'みかん', 'ゆら早生みかん'],
        potName: ['ぶなしめじ', '玉ねぎ', '国産牛ももしゃぶしゃぶ用', 'お徳用', '黄金の味'],
      },
      groups: FareJson,
      merchandises: MerchandiseJson,
      merchandiseJson: null,
    }
  },
  components: {
    MerchandiseList,
    Campaign
  },
  mounted: function(){
    let p = this
    // ページ遷移用にアイテムリセット
    this.groups[0].items = [];

    // this.$papa.parse(this.merchandises, {
    //     header: true,
    //     dynamicTyping: false,
    //     complete: function(results) {
    //       p.groups = results.data;
    //       console.log(p.groups);
    //     },
    // });
    this.tagName = this.$route.query.tag
    let tagIds = [];
    switch (this.tagName) {
      case 'きのこフェア':
        tagIds = this.tagList.mushroomName;
        this.isTop = false;
        this.groups[0].banner = 'banner-03.png';
        this.groups[0].name = 'きのこフェア 期間：2/15(水)〜17(金)';
        break;
      case '鮮烈の3日間"超特価"':
        tagIds = this.tagList.bargainPricedName;
        this.isTop = false;
        this.groups[0].banner = 'banner-02.png'
        this.groups[0].name = '鮮烈の3日間【超特価】 期間：2/15(水)〜17(金)';
        break;
      case 'シチュー':
        tagIds = this.tagList.stewName;
        this.isTop = true;
        break;
      case 'サラダ':
        tagIds = this.tagList.saladName;
        this.isTop = true;
        break;
      case 'お鍋':
        tagIds = this.tagList.potName;
        this.isTop = true;
        break;
    }

    this.merchandises.forEach((item) => {
      p.groups.forEach((g) => {
        // console.log(tagIds)
        if(tagIds.includes(item.name)) {
          if( item.id == g.id ) {
            // console.log(item.name)
            g.items.push(item)
          }
        }
      })
    })
    console.log(this.groups)
  }
}
</script>

<style scoped lang="sass">
.global--tag
  display: inline-block
  padding: 2px 5px
  border: 1px solid $line
  border-radius: 2px
  line-height: 1.5
  font-size: 1.3rem
  font-weight: 500
  color: $text-default
  &:not(:last-child)
    margin-right: 3px
.tag
  display: inline-block
.sort-item
  display: inline-block
.tag-title
  padding-left: 10px
  margin-bottom: 30px
.right-item
  float: right
  &-text
    margin-left: 5px
    margin-right: 10px
</style>
