<template>
  <div id="app" ontouchstart="" :class=" { '-searchOpen' :  isSearchOpen} ">
    <div class="background-element" @click="closeSearchWindow" v-if="isSearchOpen"></div>
    <GlobalHeader @openSearchWindow="openSearchWindow"></GlobalHeader>
    <SearchForm v-if="isSearchOpen" @openSearchWindow="openSearchWindow" v-cloak></SearchForm>
    <div @click="closeSearchWindow">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <GlobalNavigation @openSearchWindow="openSearchWindow"></GlobalNavigation>
  </div>
</template>

<script>
import GlobalHeader from '@/components/GlobalHeader.vue'
import GlobalNavigation from '@/components/GlobalNavigation.vue'
import SearchForm from '@/components/SearchForm.vue'


export default {
  name: 'App',
  data(){
    return {
      'isSearchOpen': false
    }
  },
  components: {
    GlobalHeader,
    GlobalNavigation,
    SearchForm
  },
  created() {
    this.$watch(
      () => this.$route.fullPath,
      () => {
        this.isSearchOpen = false
      }
    )
  },
  methods: {
      openSearchWindow: function(status){
        if( status ){ this.isSearchOpen = status; return }
        this.isSearchOpen  = !this.isSearchOpen
      },
    closeSearchWindow : function () {
      this.isSearchOpen = false
     }
  }
}
</script>

<style scoped lang="sass">
  #app
    position: relative
  #app.-searchOpen
    height: 100vh
    overflow: hidden

  .background-element
    position: absolute
    top: 45px
    content: ''
    display: block
    width: 100vw
    height: 100vh
    background: rgba(#000, .75)
    @include mq()
      display: none
</style>

