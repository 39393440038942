<template>
    <div class="search--wrapper">
        <form class="search--form default--inner" action="">
            <div class="search--form-tags">
                <h2 class="default--title">特集・タグから探す</h2>
                <ul>
                    <TagItem v-for=" (tag, index) in tags " :item="tag" :key="index"></TagItem>
                </ul>
            </div>
            <div class="search--form-wrapper">
                <h2 class="default--title">日付から探す</h2>
                <ul class="search--form-dates">
                    <router-link class="search--form-date -today" tag="li" to="/toDayResult">本日<font-awesome-icon icon="fa-solid fa-angle-right" /></router-link>
                    <router-link class="search--form-date" tag="li" to="/nextDayResult">{{oneDayLaterText}}<font-awesome-icon icon="fa-solid fa-angle-right" /></router-link>
                    <router-link class="search--form-date" tag="li" to="/">{{twoDayLaterText}}<font-awesome-icon icon="fa-solid fa-angle-right" /></router-link>
                    <router-link class="search--form-date" tag="li" to="/">{{threeDayLaterText}}<font-awesome-icon icon="fa-solid fa-angle-right" /></router-link>
                </ul>
            </div>
            <div class="search--form-wrapper">
                <h2 class="default--title">キーワードから検索する</h2>
                <div class="l--flex">
                    <div class="search--form-input -text">
                        <font-awesome-icon class="icon" icon="fa-solid fa-magnifying-glass"></font-awesome-icon>
                        <input type="text" v-model="keyword" class="global--input" placeholder="検索キーワードを入力">
                    </div>
                    <button type="button" class="button button--primary">検索</button>
                </div>
            </div>
            <div class="search--form-wrapper">
                <h2 class="default--title">カテゴリーから探す</h2>
                <div class="search--form-categories l--flex">
                    <router-link to="/" class="search--form-category">野菜・果物</router-link>
                    <router-link to="/" class="search--form-category">お肉</router-link>
                    <router-link to="/categoryResult" class="search--form-category">お魚</router-link>
                    <router-link to="/" class="search--form-category">デイリー</router-link>
                    <router-link to="/" class="search--form-category">一般食品</router-link>
                    <router-link to="/" class="search--form-category">お菓子</router-link>
                    <router-link to="/" class="search--form-category">酒</router-link>
                    <router-link to="/" class="search--form-category">惣菜</router-link>
                    <router-link to="/" class="search--form-category">日用品</router-link>
                </div>
            </div>
        </form>
        <div class="search--footer">
            <router-link to="/" class="link--text">ご利用について</router-link>
            <router-link to="/" class="link--text">お問い合わせ</router-link>
            <router-link to="/" class="link--text">運営会社</router-link>
        </div>
    </div>
</template>

<script>
import TagItem from '@/components/TagItem.vue';

const today = new Date();
const week = ["日", "月", "火", "水", "木", "金", "土","日", "月", "火"];
const oneDayLater = today.getMonth() + 1 + '月' + (today.getDate() + 1) + '日' + '(' + week[today.getDay() + 1] + ')';
const twoDayLater = today.getMonth() + 1 + '月' + (today.getDate() + 2) + '日' + '(' + week[today.getDay() + 2] + ')';
const threeDayLater = today.getMonth() + 1 + '月' + (today.getDate() + 3) + '日' + '(' + week[today.getDay() + 3] + ')';

export default {
    name: 'ChirashiTop',
    data(){
        return {
            tags: [
                { name: 'きのこフェア', path: '/'},
                { name: '鮮烈の3日間"超特価"', path: '/'},
                { name: 'シチュー', path: '/'},
                { name: 'サラダ', path: '/'},
                { name: 'お鍋', path: '/'}
            ],

             keyword: null,
             oneDayLaterText: oneDayLater,
             twoDayLaterText: twoDayLater,
             threeDayLaterText: threeDayLater

        }
    },
    props: [ 'item_id' ],
    components: {
        TagItem
    },
  methods:{
  },
    mounted: function(){
    }
}
</script>

<style lang="sass" scoped>
    .icon
      margin-top: 5px
    .search
        &--wrapper
            position: fixed
            height: 80%
            max-height: 650px
            overflow: auto
            top: 45px
            left: 0
            z-index: 50
            width: 100vw
            background: $white
            box-shadow: 0px 3px 6px $shadow
            @include mq()
                top: 118px
                left: initial
                max-width: 1080px
                margin: 0 auto
                box-shadow: none
        &--footer
            margin-top: 20px
            margin-bottom: 12px
            text-align: center
        &--form
            padding-top: 45px
            &-wrapper
                margin-top: 20px
            &-dates
                display: flex
                flex-wrap: wrap
            &-date
                position: relative
                display: block
                width: 32%
                margin-bottom: 8px
                padding: 12px 0 8px
                border-radius: 5px
                background: $gray-light-4
                text-align: center
                font-size: 1.25rem
                font-weight: 500
                &:not(:nth-child(3n))
                   margin-right: 2%
                &.-today
                    font-size: 1.4rem
                svg
                    position: absolute
                    top: 30%
                    right: 10px
                    font-size: 2rem
                    color: $gray-default
            &-input
                width: calc(100% - 75px)
                height: 38px
                padding: 4px 6px 4px 12px
                margin-right: 5px
                background: $gray-light-3
                border-radius: 3px
                color: $gray-default
                display: flex
                .global--input
                    padding: 0 5px 0 10px
                svg
                    font-size: 2.2rem
                & + .button
                    width: 80px
                    height: 38px
                    line-height: 1
            &-categories
                flex-wrap: wrap
                border: 1px solid $line
                border-radius: 8px
            &-category
                width: 33.3333333333333%
                padding: 8px 0
                border-bottom: 1px solid $line
                border-right: 1px solid $line
                text-align: center
                color: $text-default
                font-size: 1.4rem
                font-weight: 500
                &:nth-child(3n)
                    border-right: 0

</style>

