<template>
  <div>
    <div id="timeline" class="default--inner">
      <h2 class="timeline--heading">スーパーTOYO 三島店からのお知らせ</h2>
      <ul class="timeline--list">
        <InformationItem v-for="(info, index) in informations" :key="index" :info="info"></InformationItem>
      </ul>
    </div>
  </div>

</template>

<script>
import InformationItem from '@/components/InformationItem.vue';
import TimeLineJson from '@/assets/json/timeline.json'


export default {
  name: 'TimeLine',
  data(){
    return {
        informations: TimeLineJson
    }
  },
  components: {
    InformationItem,
  }
}
</script>

<style lang="sass" scoped>
.timeline
    &--heading
        margin-top: 10px
        text-align: center
        font-size: 1.7rem
        font-weight: 500
    &--list
        padding-top: 15px

</style>
